import React from 'react';
import logo from './assets/images/taxi-logo.png';
import map from './assets/images/map.jpg';
import mapIcons from './assets/images/map-icons.png';
import viber from './assets/images/viber.png';
import whatsapp from './assets/images/whatsapp.png';
import phone from './assets/images/phone.png';
import './App.css';

function App() {
    const phoneNumber = '+380688837115';

    return (
        <div className="app">
            <div className='logo-wrapper heartbeat'>
                <img src={logo} alt="taxi" className='logo' />
            </div>
            <div className='phone-wrapper'>
                <h2 className="gradient-multiline">
                    <span>
                        <a href={`tel:${phoneNumber}`} style={{ textDecoration: 'underline'}}>068 883 71 15</a>
                    </span>
                </h2>
                <h2 className="gradient-multiline"><span>Диспетчер</span></h2>
                <div className='icons-wrapper'>
                    <a href={`tel:${phoneNumber}`}>
                        <img src={phone} alt="phone" className='icon' />
                    </a>
                    {/* <a href={`viber://chat/?number=${phoneNumber}`}>
                        <img src={viber} alt="viber" className='icon' />
                    </a>
                    <a href={`https://wa.me/${phoneNumber}`}>
                        <img src={whatsapp} alt="whatsapp" className='icon' />
                    </a> */}
                </div>
                <p>В комендантську годину не працюємо</p>
            </div>
            <div className='map'>
                <img src={map} alt="map" className='fade-in' />
                <img src={mapIcons} alt="map icons" className='scale-in-center' />
            </div>
        </div>
    );
}

export default App;
